// Components
import CardContainer from '@/components/ui/CardContainer'
import VuetifyToolBar from '@/components/ui/VuetifyToolBar'
// Mixins
import addonsMixin from '@/mixins/addonsMixin'
// Vuex
import { mapGetters } from 'vuex'
// Utils
import { get, isNil } from 'lodash'

export default {
  name: 'ConfigMain',
  components: {
    CardContainer,
    VuetifyToolBar
  },
  mixins: [addonsMixin],
  data() {
    return {
      items: [
        {
          description:
            'Información sobre tu negocio, la moneda con la que trabajáis y los idiomas que ofrecéis.',
          icon: 'mdi-information-outline',
          title: 'Información básica',
          name: 'configBase',
          redirectUrl: '/addons/configMainInformation/need-upgrade',
          params: [
            {
              'data-cy': 'setup-link-config-base'
            }
          ]
        },
        {
          description:
            'Permite a tus clientes que te contacten y puedan encontrar tu negocio facilmente.',
          icon: 'mdi-map-marker',
          title: 'Contacto y Redes Sociales',
          name: 'contactConfigPlace',
          redirectUrl: '/addons/configAddress/need-upgrade',
          params: [
            {
              'data-cy': 'setup-link-config-contact'
            }
          ]
        },
        {
          description:
            'Facilita a tus clientes los horarios en los que tu establecimiento permanecerá abierto.',
          icon: 'mdi-clock-outline',
          title: 'Horario de apertura',
          name: 'contactConfigSchedule',
          redirectUrl: '/addons/configSchedule/need-upgrade',
          params: [
            {
              'data-cy': 'setup-link-config-schedule'
            }
          ]
        },
        {
          description: 'Personaliza y configura como quieres que se vea tu sitio web.',
          icon: 'mdi-earth',
          title: 'Sitio web',
          name: 'configWeb',
          redirectUrl: '/addons/configSiteWeb/need-upgrade',
          params: [
            {
              'data-cy': 'setup-link-config-site'
            }
          ]
        },
        {
          description:
            'Muestra a tus clientes lo bonito que se ve tu establecimiento y tus mejores productos.',
          icon: 'mdi-image-multiple-outline',
          title: 'Galería de imágenes',
          name: 'imageGallery',
          redirectUrl: '/addons/imageGallery/need-upgrade',
          params: [
            {
              'data-cy': 'setup-link-config-gallery'
            }
          ]
        },
        {
          description:
            'Configura todo lo necesario para el funcionamiento del servicio de pedidos online.',
          icon: 'mdi-tray-full',
          title: 'Take Away / Delivery',
          name: 'takeAwayConfigBase',
          redirectUrl: '/addons/takeAway/need-upgrade',
          params: [
            {
              'data-cy': 'setup-link-config-tad'
            }
          ]
        },
        {
          description: 'Indica la disponibilidad y el horario para el servicio de pedidos.',
          icon: 'mdi-calendar-clock',
          title: 'Horario - Take Away / Delivery',
          name: 'takeAwayConfigSchedule',
          redirectUrl: '/addons/takeAway/need-upgrade',
          params: [
            {
              'data-cy': 'setup-link-config-tad-schedule'
            }
          ]
        },
        {
          description:
            'Añade las zonas en las que ofreces el servicio de Delivery, así como sus precios.',
          icon: 'mdi-map',
          title: 'Zonas de reparto - Delivery',
          name: 'takeAwayConfigAreas',
          redirectUrl: '/addons/takeAwayDeliveryAreas/need-upgrade',
          params: [
            {
              'data-cy': 'setup-link-config-tad-delivery'
            }
          ]
        },
        {
          description: 'Activa la posibilidad de realizar pagos online a través de la plataforma.',
          icon: 'mdi-credit-card-outline',
          title: 'Bakarta Pay',
          name: 'bakartaPay',
          redirectUrl: '/addons/bakartaPay/need-upgrade',
          params: [
            {
              'data-cy': 'setup-link-config-bakarta-pay'
            }
          ]
        },
        {
          description:
            'Obtén los enlaces y descarga los QRs de tus cartas. Además de imprimir tus cartas en papel.',
          icon: 'mdi-share',
          title: 'Compartir e imprimir cartas',
          name: 'shareMenuLink',
          redirectUrl: '/addons/shareMenuLink/need-upgrade',
          params: [
            {
              'data-cy': 'setup-link-config-share-menu'
            }
          ]
        },
        {
          description:
            'Gestiona los miembros de tu equipo que te ayudarán a gestionar tu establecimiento.',
          icon: 'mdi-account-multiple',
          title: 'Equipo',
          name: 'manageUsers',
          redirectUrl: '/addons/team/need-upgrade',
          params: [
            {
              'data-cy': 'setup-link-config-manage-users'
            }
          ]
        },
        {
          description:
            'Define los tipos de "raciones", "porciones", "tamaños"... que usas en tu establecimiento.',
          icon: 'mdi-numeric',
          title: 'Raciones',
          name: 'rations',
          redirectUrl: '/addons/portions/need-upgrade',
          params: [
            {
              'data-cy': 'setup-link-config-rations'
            }
          ]
        },
        {
          description: 'Otras configuraciones a definir.',
          icon: 'mdi-application-cog',
          title: 'Varios',
          name: 'configMiscellaneous',
          redirectUrl: '/addons/configMainInformation/need-upgrade',
          params: [
            {
              'data-cy': 'setup-link-config-miscellaneous'
            }
          ]
        }
      ]
    }
  },
  computed: {
    ...mapGetters('authentication', ['userData']),
    /**
     * Parsemos los items de configuración para enviar
     * a su correspondiente pantalla o a una pantalla
     * de "debe adquirir la funcionalidad"
     *
     * @return {array}
     */
    parsedItems() {
      // All routes links
      const allRoutes = get(this, '$router.options.routes[0].children', [])

      return this.items.reduce((sumItems, item) => {
        // Obtenemos la ruta actual, si esta existe en "routesName"
        const currentRoute = allRoutes.find((route) => route.name === item.name)

        if (currentRoute) {
          // Roles de la ruta
          const currentRouteRoles = get(currentRoute, 'meta.roles', null)
          // AddOns de la ruta
          const currentRouteAddOns = get(currentRoute, 'meta.addons', null)
          // AddOns adquiridos
          const acquiredAddons = this.acquiredAddons
          // Tenemos permiso por rol de usuario?
          const hasRoles =
            isNil(currentRouteRoles) || currentRouteRoles.indexOf(this.userData.role) > -1
          // Tenemos permiso por addOns adquiridas?
          const hasAddOns =
            isNil(currentRouteAddOns) ||
            currentRouteAddOns.every((addon) => {
              return acquiredAddons.indexOf(addon) > -1
            })

          if (hasRoles) {
            sumItems.push({
              ...item,
              path: hasAddOns ? currentRoute.path : item.redirectUrl
            })
          }
        }

        return sumItems
      }, [])
    }
  }
}
